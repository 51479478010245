import { cn } from "~/utils/cn";

export const NewCountBadge = ({
  count,
  colorDown
}: {
  count?: number | undefined;
  colorDown?: boolean | undefined;
}) =>
  typeof count !== "number" || count <= 0 ? (
    <></>
  ) : (
    <div
      className={cn(
        "rounded-full bg-red-500 px-1.5 py-[1px] font-thin text-[11px] text-gray-100",
        colorDown && "bg-red-500/80 text-gray-100/80"
      )}
    >
      {count > 99 ? "99+" : count}
    </div>
  );
